import axios from 'axios'
import {CREATE, UPDATE} from "react-admin";
import moment from "moment";

export function uploadFile(file, folder, fileName) {
    let formData = new FormData();
    formData.append("file", file);
    
    return axios.post(process.env.REACT_APP_SERVER + `/api/upload/${folder}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
}

export default (dataProvider) => (fetchType, resource, params) => {
    const promises = []
    if ((resource === "Application" || resource === "Record"|| resource === "Visit" || resource === "Post"  ) && (fetchType === CREATE || fetchType === UPDATE)) {
        const {data, ...rest_params} = params;

        if(params.data.photo && params.data.photo.rawFile instanceof File) {
           promises.push(uploadFile(data.photo.rawFile, 'photos').then(res => {
               params.data.photo = res.data.filePath
            })
           )
         }

        if(params.data.place && params.data.place.photo && params.data.place.photo.rawFile instanceof File) {
            promises.push(uploadFile(data.place.photo.rawFile, 'photos').then(res => {
                    params.data.place.photo = res.data.filePath
                })
            )
        }

        if(params.data.story && params.data.story.photos ) {
            for(const p of params.data.story.photos) {
                if(p.filePath && p.filePath.rawFile instanceof File) {
                    promises.push(uploadFile(p.filePath.rawFile, 'photos').then(res => {
                        p.filePath = res.data.filePath
                        })
                    )
                }
            }
        }

        if(params.data.story && params.data.story.files ) {
            for(const p of params.data.story.files) {
                if(p.filePath && p.filePath.rawFile instanceof File) {
                    promises.push(uploadFile(p.filePath.rawFile, 'post_attachments', params.data.registrationId+"-"+p.title||p.type||''+"-file").then(res => {
                            p.filePath = res.data.filePath
                        })
                    )
                }
            }
        }

        if(params.data.files ) {
            for(const p of params.data.files) {
                if(p.filePath && p.filePath.rawFile instanceof File) {
                    promises.push(uploadFile(p.filePath.rawFile, 'post_attachments', (moment(params.data.date).format("YYYY-MM-DDTHH-mm-ss"))+(params.data.recordId||'')+"-"+(p.title||params.data.title||'')+"-file").then(res => {
                            p.filePath = res.data.filePath
                        })
                    )
                }
            }
        }

        if(params.data.families) {
            for(const f of params.data.families) {
                if(f.photo && f.photo.rawFile instanceof File){
                    promises.push(uploadFile(f.photo.rawFile, 'photos').then(res => {
                        f.photo = res.data.filePath
                    }))
                }
            }
        }

        if ((resource === "Visit")) {
            if(params.data.expense && params.data.expense.rent && params.data.expense.rent.photo && params.data.expense.rent.photo.rawFile instanceof File) {
                promises.push(uploadFile(params.data.expense.rent.photo.rawFile, 'photos').then(res => {
                    params.data.expense.rent.photo = res.data.filePath
                    })
                )
            }

            if(params.data.livingCondition && params.data.livingCondition.photos ) {
                params.data.livingCondition.photos.map((p,i) => {
                    if(p && p.rawFile instanceof File) {
                        promises.push(uploadFile(p.rawFile, 'photos').then(res => {
                                params.data.livingCondition.photos[i] = res.data.filePath
                            })
                        )
                    } else {
                        params.data.livingCondition.photos[i] = ''
                    }
                })
            }

            if(params.data.visitPhotos) {
                for(const p of params.data.visitPhotos) {
                    if(p && p.filePath && p.filePath.rawFile instanceof File) {
                        promises.push(uploadFile(p.filePath.rawFile, 'photos').then(res => {
                                p.filePath = res.data.filePath
                            })
                        )
                    }
                }
            }
        }

        return Promise.all(promises).then(() => {
            return dataProvider(fetchType, resource, {
                ...rest_params,
                data: {...data},
            });
        })
    }

    return dataProvider(fetchType, resource, params);
};


